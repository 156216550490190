import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5375 8126 c-14 -10 -17 -26 -16 -84 1 -40 -3 -72 -8 -72 -5 0 -32
11 -59 25 -28 14 -52 25 -55 25 -3 0 -7 -19 -9 -42 -3 -40 -5 -43 -33 -45
-272 -23 -497 -129 -672 -317 -61 -65 -73 -88 -53 -101 6 -3 10 -21 10 -38 0
-26 11 -43 53 -85 55 -54 76 -62 97 -37 19 22 4 48 -62 109 l-61 57 79 78 c43
43 107 97 140 120 129 86 298 145 439 153 l60 3 3 -37 c2 -21 8 -38 13 -38 5
0 32 11 59 25 27 14 52 25 55 25 3 0 5 -38 5 -84 0 -90 6 -102 46 -92 23 6 24
9 24 96 0 49 5 101 12 115 10 21 9 28 -2 35 -11 7 -13 31 -11 104 2 85 1 96
-17 105 -14 7 -24 7 -37 -3z"/>
<path d="M4898 7769 c-25 -14 -22 -45 12 -117 31 -66 51 -82 75 -62 23 19 18
51 -17 121 -35 68 -43 74 -70 58z"/>
<path d="M5853 7772 c-15 -10 -63 -124 -63 -151 0 -27 28 -47 48 -35 15 10 72
136 72 161 0 7 -9 17 -19 23 -23 12 -23 12 -38 2z"/>
<path d="M4297 7683 c-19 -18 -3 -53 47 -102 59 -59 98 -67 104 -22 3 17 -10
37 -51 77 -50 49 -83 65 -100 47z"/>
<path d="M6392 7637 c-29 -29 -52 -59 -52 -67 0 -7 -8 -22 -17 -32 -17 -19
-16 -21 27 -56 200 -164 322 -397 337 -641 l6 -101 -46 0 c-26 0 -47 -2 -47
-5 0 -3 11 -28 25 -56 30 -58 30 -57 -70 -61 -68 -3 -70 -4 -73 -30 -4 -35 19
-41 127 -37 77 4 80 3 91 -21 l12 -25 13 25 c12 23 16 24 108 21 84 -2 97 0
105 16 20 37 -2 53 -78 53 -38 0 -71 4 -74 9 -3 5 5 29 18 55 13 25 21 48 18
51 -3 3 -20 5 -37 5 l-32 0 -7 83 c-7 73 -22 165 -41 237 -5 23 -1 27 45 45
64 25 88 49 74 73 -16 25 -47 26 -93 3 -22 -12 -46 -21 -53 -21 -7 0 -24 23
-38 52 -38 77 -103 165 -180 244 l-69 71 54 53 c56 55 70 92 39 104 -29 11
-40 6 -92 -47z"/>
<path d="M6203 7448 c-54 -55 -62 -75 -41 -96 21 -21 38 -14 94 40 43 40 54
57 52 77 -2 19 -10 27 -28 29 -19 2 -38 -10 -77 -50z"/>
<path d="M5240 7413 c-56 -9 -160 -48 -244 -91 -149 -76 -302 -238 -368 -390
-52 -122 -70 -210 -70 -352 1 -186 52 -346 156 -485 115 -154 269 -262 461
-321 87 -27 354 -27 440 0 280 87 482 280 578 553 29 84 31 96 31 248 1 183
-9 235 -76 375 -45 94 -75 137 -157 224 -102 107 -270 200 -420 232 -61 13
-270 17 -331 7z m233 -83 c115 -54 165 -186 111 -291 -16 -32 -78 -87 -112
-100 -14 -6 -59 -14 -100 -19 -40 -5 -92 -18 -115 -28 -64 -29 -133 -96 -164
-160 -24 -50 -28 -69 -28 -147 0 -78 4 -97 28 -147 17 -35 48 -75 79 -102 l52
-45 -28 -22 c-49 -38 -68 -62 -93 -118 -22 -46 -25 -63 -21 -131 3 -51 11 -88
22 -105 22 -34 15 -41 -25 -24 -95 39 -224 146 -307 255 -79 104 -142 300
-142 444 1 199 100 418 254 563 156 145 462 237 589 177z m259 -61 c299 -152
473 -489 413 -804 -33 -178 -96 -298 -220 -421 -113 -112 -239 -177 -405 -208
-104 -20 -143 -20 -211 0 -110 33 -172 136 -148 244 25 110 90 158 229 169
153 13 252 77 307 199 65 141 20 315 -103 404 l-44 31 46 49 c25 27 54 67 64
91 24 52 26 155 4 212 -29 77 -29 77 -1 66 15 -6 46 -20 69 -32z m-307 -439
c33 -13 28 -33 -11 -40 -17 -3 -33 -14 -39 -27 -7 -16 -15 -21 -27 -17 -14 4
-18 0 -18 -20 0 -14 -4 -26 -10 -26 -5 0 -10 -8 -10 -18 0 -10 -11 -29 -25
-42 -27 -26 -34 -77 -13 -98 7 -7 26 -12 44 -12 38 0 53 -16 61 -67 3 -21 12
-50 19 -65 17 -33 46 -28 77 13 24 33 37 37 37 12 0 -15 2 -14 15 3 19 25 19
38 0 30 -26 -9 -29 21 -6 57 12 19 21 44 21 55 0 11 5 24 10 27 6 3 10 15 10
26 0 33 33 19 44 -18 11 -34 26 -28 20 7 -4 18 -2 30 4 30 23 0 24 -83 3 -140
-38 -102 -114 -160 -219 -168 -77 -5 -131 13 -191 63 -30 26 -33 32 -28 69 5
32 1 46 -14 63 -27 28 -41 91 -29 133 14 48 38 80 60 80 11 0 27 11 35 24 14
20 22 24 50 19 35 -6 55 6 68 40 7 19 24 21 62 7z m16 -160 c10 0 19 -4 19
-10 0 -5 -16 -10 -35 -10 -25 0 -35 4 -35 16 0 11 5 14 16 10 9 -3 24 -6 35
-6z"/>
<path d="M4213 7089 c-27 -27 -14 -46 55 -78 76 -34 104 -38 122 -16 20 24 4
44 -53 70 -70 32 -109 40 -124 24z"/>
<path d="M6464 7070 c-68 -31 -78 -41 -69 -70 8 -26 38 -26 106 1 67 27 93 53
79 80 -14 26 -43 23 -116 -11z"/>
<path d="M4057 6624 c-4 -4 -50 -8 -103 -8 -79 -1 -99 -4 -107 -18 -21 -32 8
-48 89 -48 85 0 86 -2 49 -74 -35 -69 -33 -76 20 -76 25 0 45 -3 45 -6 0 -50
38 -239 62 -304 53 -148 164 -314 266 -398 l44 -36 -22 -15 c-13 -9 -43 -36
-67 -61 -50 -51 -56 -92 -15 -98 29 -4 132 100 132 134 0 17 12 28 48 45 49
23 142 110 142 132 0 18 -21 37 -40 37 -18 0 -120 -100 -120 -118 0 -39 -76
20 -164 126 -108 133 -186 321 -202 490 l-7 72 31 0 c17 0 33 4 36 8 3 5 -8
36 -24 68 -16 32 -30 62 -30 67 0 4 38 7 84 7 90 0 113 11 101 50 -6 18 -14
20 -103 20 -53 0 -106 3 -117 6 -11 3 -24 2 -28 -2z"/>
<path d="M4270 6163 c-75 -34 -92 -64 -47 -84 17 -8 37 -4 91 19 77 31 97 51
77 76 -19 22 -56 19 -121 -11z"/>
<path d="M6395 6170 c-9 -30 7 -45 82 -74 66 -27 97 -27 107 -1 8 21 -23 50
-81 74 -66 27 -100 27 -108 1z"/>
<path d="M6156 5814 c-3 -9 -6 -20 -6 -25 0 -19 103 -119 121 -119 28 0 23
-13 -26 -68 -154 -171 -415 -292 -632 -292 l-52 0 -3 35 -3 35 -60 -30 -60
-30 -5 88 -5 87 -30 0 -30 0 -1 -102 c-1 -64 -5 -108 -13 -117 -10 -11 -9 -16
2 -23 10 -6 12 -24 7 -78 -8 -94 -1 -129 29 -133 33 -5 41 12 41 89 0 36 3 69
6 72 4 3 32 -7 63 -23 l56 -30 5 47 5 48 110 13 c132 16 272 61 380 123 87 49
197 139 238 193 l28 36 61 -65 c34 -36 69 -65 78 -65 21 0 40 19 40 40 0 20
-112 130 -132 130 -14 0 -58 51 -58 67 0 5 -24 32 -53 61 -53 53 -90 66 -101
36z"/>
<path d="M4941 5577 c-21 -26 -62 -130 -59 -151 2 -16 10 -21 32 -21 30 0 32
2 65 77 25 57 27 94 5 102 -23 9 -30 8 -43 -7z"/>
<path d="M5796 5574 c-20 -19 -21 -9 10 -85 32 -78 40 -89 70 -89 35 0 37 29
8 101 -38 93 -54 107 -88 73z"/>
<path d="M2694 4981 c-7 -7 -5 -1128 2 -1145 5 -14 48 -16 376 -16 l369 0 -2
580 c-1 423 -4 580 -13 580 -297 4 -729 4 -732 1z m561 -379 c48 -16 50 -18
47 -52 -6 -57 -15 -61 -84 -33 -49 20 -73 24 -114 20 -104 -11 -146 -72 -146
-212 0 -146 44 -200 162 -199 88 0 99 9 96 82 -3 68 6 82 49 82 l32 0 6 -102
c4 -57 3 -106 -1 -110 -16 -16 -126 -38 -191 -38 -169 0 -260 114 -249 308 8
139 70 230 177 263 46 13 164 9 216 -9z"/>
<path d="M4510 4980 l-835 -5 -3 -577 -2 -578 1195 0 1195 0 0 580 0 580 -27
0 c-16 0 -176 1 -358 2 -181 2 -706 1 -1165 -2z m-436 -379 c3 -5 3 -23 0 -40
l-6 -31 -109 0 -109 0 0 -80 0 -80 90 0 90 0 0 -40 0 -40 -90 0 -90 0 0 -120
0 -120 -45 0 -45 0 0 280 0 280 154 0 c85 0 157 -4 160 -9z m256 -327 l0 -244
-40 0 -40 0 0 243 c0 134 2 246 5 248 2 3 20 3 40 1 l35 -4 0 -244z m456 227
c80 -49 84 -190 8 -250 -13 -10 -24 -25 -24 -33 0 -8 25 -50 55 -94 50 -72 54
-80 38 -91 -45 -34 -77 -9 -143 110 -29 52 -35 57 -66 57 l-34 0 0 -85 0 -85
-40 0 -40 0 0 245 0 245 108 0 c87 0 113 -4 138 -19z m484 8 c30 -11 32 -14
27 -47 -8 -46 -12 -48 -52 -29 -48 23 -100 22 -125 -3 -41 -41 -25 -71 61
-116 37 -20 82 -49 99 -65 25 -25 30 -37 30 -78 0 -103 -69 -157 -189 -149
-83 6 -111 22 -111 63 0 51 17 58 66 29 58 -34 122 -31 143 7 23 41 8 74 -48
101 -84 43 -121 69 -136 98 -32 62 -7 141 57 180 38 23 125 27 178 9z m500
-24 l0 -35 -65 0 -65 0 0 -210 0 -210 -30 0 c-16 0 -33 -1 -37 -2 -5 -2 -10
94 -13 211 l-5 215 -59 -3 c-64 -3 -76 5 -68 45 l4 24 169 0 169 0 0 -35z"/>
<path d="M4620 4365 l0 -85 48 0 c53 1 77 14 92 54 16 42 12 65 -15 91 -19 20
-34 25 -75 25 l-50 0 0 -85z"/>
<path d="M7154 4982 l-851 -2 -6 -68 c-4 -37 -7 -298 -7 -580 l0 -512 943 2
942 3 3 532 2 532 -57 3 -58 3 -5 44 c-4 38 -8 44 -30 45 -14 1 -408 0 -876
-2z m-401 -373 c44 -12 111 -69 134 -113 10 -19 18 -67 21 -122 8 -158 -38
-259 -142 -308 -49 -23 -67 -26 -163 -26 -166 0 -148 -35 -147 288 0 152 1
280 2 285 3 11 252 8 295 -4z m469 -289 c43 -129 78 -240 78 -247 0 -8 -14
-13 -38 -13 l-39 0 -20 73 c-37 128 -96 312 -103 320 -4 4 -19 -27 -34 -70
-15 -43 -40 -114 -56 -158 -16 -44 -34 -99 -40 -122 -10 -42 -12 -43 -50 -43
-22 0 -40 3 -40 8 0 10 166 468 175 482 4 6 25 10 48 8 l40 -3 79 -235z m692
3 c43 -131 80 -244 83 -251 4 -10 -6 -12 -37 -10 l-43 3 -48 155 c-27 85 -54
174 -60 198 -6 23 -14 42 -18 42 -7 0 -95 -246 -122 -340 -14 -51 -18 -55 -49
-58 -18 -2 -36 0 -40 5 -7 7 50 174 146 426 l26 67 42 0 42 0 78 -237z m-289
187 l0 -35 -65 0 -65 0 -3 -212 -2 -213 -40 0 -40 0 0 215 0 216 -59 -7 c-33
-3 -63 -4 -68 -1 -5 3 -7 22 -5 42 l4 35 172 -2 171 -3 0 -35z"/>
<path d="M6547 4381 c-4 -87 -4 -182 0 -211 l6 -52 82 4 c129 7 172 51 182
191 4 58 1 83 -16 129 -28 74 -72 98 -178 98 l-70 0 -6 -159z"/>
<path d="M6428 3739 c-3 -41 -4 -43 -47 -53 -56 -14 -81 -47 -83 -107 -1 -55
15 -87 48 -99 38 -15 51 -12 74 12 l21 23 6 -23 c3 -12 11 -22 18 -22 17 0 37
25 27 34 -4 4 -9 67 -12 139 -5 126 -6 132 -27 135 -19 3 -22 -2 -25 -39z m0
-116 c5 -33 -17 -103 -35 -110 -38 -15 -58 71 -27 115 20 29 58 26 62 -5z"/>
<path d="M6852 3768 c-15 -15 -17 -224 -3 -261 6 -14 19 -28 30 -32 27 -8 61
3 61 20 0 7 -9 15 -20 18 -19 5 -20 14 -20 136 0 119 -2 131 -18 131 -10 0
-23 -5 -30 -12z"/>
<path d="M4066 3724 c-9 -8 -16 -22 -16 -30 0 -8 -9 -14 -20 -14 -13 0 -20 -7
-20 -20 0 -13 7 -20 20 -20 18 0 20 -7 20 -68 0 -83 18 -108 69 -97 38 8 45
46 9 42 -21 -2 -23 3 -26 56 -3 53 -1 58 22 67 32 12 34 37 4 42 -17 2 -24 11
-26 31 -4 31 -14 34 -36 11z"/>
<path d="M5987 3729 c-9 -6 -17 -18 -17 -28 0 -23 -41 -33 -80 -18 -80 30
-155 -100 -104 -178 30 -45 134 -55 134 -13 0 17 -5 20 -30 15 -40 -9 -60 14
-60 68 0 50 22 81 48 66 9 -5 23 -6 32 -1 8 4 25 4 38 -1 20 -8 22 -15 22 -78
0 -61 3 -72 21 -81 30 -17 79 -3 79 21 0 15 -6 19 -22 17 -21 -3 -23 1 -26 59
-3 57 -1 62 20 65 34 5 37 38 4 38 -21 0 -26 5 -26 24 0 30 -10 37 -33 25z"/>
<path d="M3890 3677 c-46 -23 -38 -87 13 -113 59 -31 58 -54 -4 -54 -49 0 -54
-25 -6 -38 44 -12 89 7 102 42 12 34 0 54 -43 75 -56 28 -53 66 4 55 29 -5 34
-4 34 14 0 14 -9 21 -31 26 -41 8 -40 8 -69 -7z"/>
<path d="M4209 3661 c-25 -25 -29 -37 -29 -83 0 -61 17 -93 56 -102 20 -5 35
-1 55 13 28 21 28 21 34 1 7 -21 14 -24 39 -14 11 4 13 11 5 31 -5 13 -9 57
-9 97 l0 74 -31 6 c-62 12 -90 7 -120 -23z m99 -38 c5 -33 -17 -103 -35 -110
-26 -10 -45 26 -40 76 3 24 11 47 18 52 23 15 54 6 57 -18z"/>
<path d="M4777 3669 c-63 -49 -58 -167 8 -195 45 -18 95 -9 95 19 0 16 -5 19
-30 14 -40 -9 -60 14 -60 68 0 50 22 80 48 66 21 -11 42 -4 42 14 0 17 -31 35
-58 35 -11 0 -31 -9 -45 -21z"/>
<path d="M4941 3665 c-60 -51 -51 -164 16 -191 43 -18 77 -9 109 29 23 27 26
38 22 86 -4 44 -10 59 -32 78 -35 30 -77 29 -115 -2z m76 -27 c17 -21 23 -76
13 -104 -8 -20 -16 -25 -38 -22 -25 3 -27 6 -30 56 -3 54 8 82 33 82 7 0 17
-6 22 -12z"/>
<path d="M5198 3676 c-16 -9 -30 -11 -33 -5 -4 5 -18 9 -32 9 -22 0 -24 -3
-18 -22 4 -13 9 -60 12 -105 5 -79 7 -83 29 -83 23 0 24 4 24 60 0 64 14 105
38 115 25 9 30 -6 34 -90 3 -78 4 -80 28 -80 25 0 25 1 25 88 0 81 -2 90 -24
108 -28 23 -47 24 -83 5z"/>
<path d="M5420 3673 c-17 -12 -25 -13 -28 -5 -2 7 -15 12 -29 12 -22 0 -24 -3
-19 -22 4 -13 10 -60 13 -105 6 -79 7 -83 29 -83 23 0 24 3 24 68 0 74 15 106
46 100 16 -3 20 -14 24 -83 5 -74 7 -80 28 -83 21 -3 22 -1 22 87 0 78 -3 94
-20 111 -24 24 -58 26 -90 3z"/>
<path d="M5628 3673 c-34 -21 -48 -53 -48 -108 0 -65 32 -98 93 -98 54 1 67 8
67 32 0 14 -9 17 -47 17 -36 -1 -48 3 -51 15 -2 11 7 18 30 23 68 14 99 75 58
116 -24 24 -66 25 -102 3z m70 -45 c4 -21 -45 -55 -59 -41 -6 6 -6 19 1 37 7
20 16 27 32 24 13 -2 24 -11 26 -20z"/>
<path d="M6163 3680 c-55 -22 -82 -130 -45 -178 10 -12 31 -26 47 -31 37 -9
105 6 105 25 0 20 -16 25 -45 14 -18 -7 -33 -7 -46 0 -26 15 -24 40 4 40 22 0
65 24 79 44 16 22 8 65 -14 80 -25 18 -52 19 -85 6z m53 -45 c4 -8 1 -22 -6
-30 -18 -21 -50 -19 -50 3 0 36 45 57 56 27z"/>
<path d="M6673 3680 c-49 -20 -72 -135 -35 -179 27 -33 75 -38 101 -10 l22 24
6 -23 c8 -30 50 -27 45 3 -2 11 -6 57 -9 101 -5 81 -6 82 -35 88 -40 8 -68 7
-95 -4z m75 -57 c5 -33 -17 -103 -35 -110 -38 -15 -58 71 -27 115 20 29 58 26
62 -5z"/>
<path d="M7343 3680 c-30 -12 -63 -68 -63 -105 0 -41 34 -93 65 -101 16 -4 35
0 52 11 24 16 27 16 38 1 7 -9 19 -16 28 -16 13 0 14 14 10 103 -3 56 -7 103
-8 105 -7 8 -103 10 -122 2z m71 -46 c3 -8 4 -26 1 -40 -3 -13 -7 -35 -10 -48
-6 -28 -32 -41 -51 -25 -19 16 -18 94 2 113 20 21 50 20 58 0z"/>
<path d="M7770 3683 c-59 -21 -48 -107 16 -127 24 -7 39 -43 20 -49 -6 -2 -23
-1 -38 4 -49 14 -55 -23 -5 -37 44 -14 79 -3 96 28 20 35 2 76 -39 91 -55 21
-50 62 6 51 29 -5 34 -4 34 14 0 26 -48 40 -90 25z"/>
<path d="M4400 3611 c0 -116 28 -153 96 -131 42 14 56 5 43 -29 -8 -21 -14
-23 -53 -18 -35 5 -45 3 -50 -10 -10 -26 9 -36 65 -36 74 0 92 24 85 113 -3
36 -6 91 -6 123 0 54 -1 57 -25 57 -24 0 -25 -3 -25 -61 0 -73 -12 -99 -45
-99 -24 0 -25 2 -25 80 l0 80 -30 0 -30 0 0 -69z"/>
<path d="M6960 3599 c0 -44 5 -89 10 -99 17 -32 47 -43 84 -30 23 7 41 7 60 1
68 -27 118 17 131 114 7 60 -5 95 -34 95 -17 0 -19 -10 -23 -80 -3 -82 -14
-105 -45 -93 -9 4 -13 28 -13 89 0 82 -1 84 -24 84 -22 0 -24 -4 -28 -81 -5
-81 -16 -103 -45 -92 -9 4 -13 28 -13 89 l0 84 -30 0 -30 0 0 -81z"/>
<path d="M7500 3600 c0 -73 3 -83 25 -105 27 -28 42 -30 80 -13 34 15 52 -1
35 -32 -10 -20 -18 -22 -55 -17 -34 5 -44 3 -49 -10 -8 -20 -5 -23 40 -33 42
-10 98 9 108 36 3 8 3 69 -1 135 -6 116 -7 119 -30 119 -22 0 -23 -4 -23 -61
0 -73 -12 -99 -45 -99 -24 0 -25 2 -25 80 l0 80 -30 0 -30 0 0 -80z"/>
<path d="M7891 3513 c-13 -26 1 -45 30 -41 18 2 24 9 24 28 0 32 -39 41 -54
13z"/>
<path d="M8001 3513 c-13 -26 1 -45 30 -41 18 2 24 9 24 28 0 32 -39 41 -54
13z"/>
<path d="M8111 3513 c-13 -26 1 -45 30 -41 18 2 24 9 24 28 0 32 -39 41 -54
13z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
